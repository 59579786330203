import { builder } from '@builder.io/react';
import { BuilderContent } from '@builder.io/sdk';
import { BUILDER_API_KEY } from '../shared/env';
import { Message } from '../types/Message';

builder.init(BUILDER_API_KEY);

export const getAllMessage = async (
  includeUnpublished = false,
  includeDraft = false
) => {
  const draft = includeDraft ?? 'draft';
  const messages = await builder.getAll('message', {
    options: { noTargeting: true, includeUnpublished },
    query: {
      published: {
        $ni: ['archived', draft],
      },
    },
  });
  return messages;
};

export const getMessage = async (
  params: { id?: string },
  includeUnpublished = false
) => {
  const queryOption: any = {
    data: {},
    options: {
      includeUnpublished,
      noTargeting: false,
    },
    query: {},
  };
  if (params.id) {
    queryOption.query.id = params.id;
  }

  const message = (await builder.get('message', queryOption).promise()) || null;
  return message;
};

export const getValidMessage = (
  messages: BuilderContent[]
): Message | undefined => {
  if (!messages) return;
  const message = messages.find((mess) => {
    const startDate = (
      mess.data?.startDate
        ? new Date(mess.data.startDate)
        : new Date(2000, 1, 1)
    ).getTime();
    const endDate = (
      mess.data?.endDate ? new Date(mess.data.endDate) : new Date(9999, 1, 1)
    ).getTime();
    const isRead = localStorage.getItem(mess.id!);
    const now = Date.now();
    const isValid = now > startDate && now < endDate;
    return isValid && !isRead;
  });
  if (message && message.data) {
    return { ...(message.data as Message), id: message.id };
  }
  return undefined;
};
