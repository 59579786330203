import dynamic from 'next/dynamic';
import React from 'react';

const AfterpayLogo = dynamic(() =>
  import('./icons/AfterpayLogo').then((module) => module as any)
);
const AppleStore = dynamic(() =>
  import('./icons/AppleStore').then((module) => module as any)
);
const ArrowLeft = dynamic(() => import('./icons/ArrowLeft'));
const ArrowRight = dynamic(() => import('./icons/ArrowRight'));
const ClubHouse = dynamic(() =>
  import('./icons/ClubHouse').then((module) => module as any)
);
const Down = dynamic(() =>
  import('./icons/Down').then((module) => module as any)
);
const Facebook = dynamic(() =>
  import('./icons/Facebook').then((module) => module as any)
);
const FourPointedStart = dynamic(() =>
  import('./icons/FourPointedStart').then((module) => module as any)
);
const ImagePageNotFound = dynamic(() =>
  import('./icons/ImagePageNotFound').then((module) => module as any)
);
const Instagram = dynamic(() => import('./icons/Instagram'));
const Left = dynamic(() =>
  import('./icons/Left').then((module) => module as any)
);
const Mail = dynamic(() =>
  import('./icons/Mail').then((module) => module as any)
);
const Menu = dynamic(() =>
  import('./icons/Menu').then((module) => module as any)
);
const MenuClose = dynamic(() =>
  import('./icons/MenuClose').then((module) => module as any)
);
const Messenger = dynamic(() =>
  import('./icons/Messenger').then((module) => module as any)
);
const MoneyByAfterpay = dynamic(() =>
  import('./icons/MoneyByAfterpay').then((module) => module as any)
);
const MoneyLogo = dynamic(() =>
  import('./icons/MoneyLogo').then((module) => module as any)
);
const NumberFour = dynamic(() =>
  import('./icons/NumberFour').then((module) => module as any)
);
const Play = dynamic(() =>
  import('./icons/Play').then((module) => module as any)
);
const Reddit = dynamic(() =>
  import('./icons/Reddit').then((module) => module as any)
);
const Right = dynamic(() =>
  import('./icons/Right').then((module) => module as any)
);
const ShareLink = dynamic(() =>
  import('./icons/ShareLink').then((module) => module as any)
);
const SMS = dynamic(() =>
  import('./icons/SMS').then((module) => module as any)
);
const StreamlineIconInterfaceFileText = dynamic(() =>
  import('./icons/StreamlineIconInterfaceFileText').then(
    (module) => module as any
  )
);
const StreamlineIconInterfaceLock = dynamic(() =>
  import('./icons/StreamlineIconInterfaceLock').then((module) => module as any)
);
const TikTok = dynamic(() =>
  import('./icons/TikTok').then((module) => module as any)
);

export enum IconType {
  AfterpayLogo = 'AfterpayLogo',
  AppleStore = 'AppleStore',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ClubHouse = 'ClubHouse',
  Down = 'Down',
  FourPointedStart = 'FourPointedStart',
  Instagram = 'Instagram',
  Left = 'Left',
  MoneyLogo = 'MoneyLogo',
  Play = 'Play',
  Reddit = 'Reddit',
  Right = 'Right',
  Messenger = 'Messenger',
  Mail = 'Mail',
  SMS = 'SMS',
  ShareLink = 'ShareLink',
  Menu = 'Menu',
  MenuClose = 'MenuClose',
  MoneyByAfterpay = 'MoneyByAfterpay',
  StreamlineIconInterfaceLock = 'StreamlineIconInterfaceLock',
  StreamlineIconInterfaceFileText = 'StreamlineIconInterfaceFileText',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  NumberFour = 'NumberFour',
  ImagePageNotFound = 'ImagePageNotFound',
}

interface IconProps {
  type: IconType | undefined;
  stroke?: string;
}

const Icon = ({ type, stroke }: IconProps) => {
  switch (type) {
    case IconType.AfterpayLogo:
      return <AfterpayLogo />;
    case IconType.AppleStore:
      return <AppleStore />;
    case IconType.ArrowLeft:
      return <ArrowLeft stroke={stroke} />;
    case IconType.ArrowRight:
      return <ArrowRight stroke={stroke} />;
    case IconType.ClubHouse:
      return <ClubHouse />;
    case IconType.Down:
      return <Down />;
    case IconType.Instagram:
      return <Instagram stroke={stroke} />;
    case IconType.Facebook:
      return <Facebook />;
    case IconType.FourPointedStart:
      return <FourPointedStart />;
    case IconType.Left:
      return <Left />;
    case IconType.MoneyLogo:
      return <MoneyLogo />;
    case IconType.Play:
      return <Play />;
    case IconType.Reddit:
      return <Reddit />;
    case IconType.Right:
      return <Right />;
    case IconType.StreamlineIconInterfaceLock:
      return <StreamlineIconInterfaceLock />;
    case IconType.StreamlineIconInterfaceFileText:
      return <StreamlineIconInterfaceFileText />;
    case IconType.TikTok:
      return <TikTok />;
    case IconType.Messenger:
      return <Messenger />;
    case IconType.Mail:
      return <Mail />;
    case IconType.SMS:
      return <SMS />;
    case IconType.ShareLink:
      return <ShareLink />;
    case IconType.Menu:
      return <Menu />;
    case IconType.MenuClose:
      return <MenuClose />;
    case IconType.MoneyByAfterpay:
      return <MoneyByAfterpay />;
    case IconType.NumberFour:
      return <NumberFour />;
    case IconType.ImagePageNotFound:
      return <ImagePageNotFound />;
    default:
      return null;
  }
};

export default React.memo(Icon);
