import Link from 'next/link';
import React from 'react';
import BlockNav from '../../components/block-nav/block-nav';
import Button from '../../components/Button';
import DefaultLayout from '../../components/default-layout/default-layout';
import Icons, { IconType } from '../../components/Icons';
import styles from './404.module.scss';
const NotFoundPage = () => {
  return (
    <DefaultLayout>
      <BlockNav />
      <div className={styles.pageNotFound}>
        <div className={styles.imageContainer}>
          <div className={styles.imageFirst}>
            <Icons type={IconType.NumberFour} />
          </div>
          <div className={styles.imageSecond}>
            <Icons type={IconType.ImagePageNotFound} />
          </div>
          <div className={styles.imageThirst}>
            <Icons type={IconType.NumberFour} />
          </div>
        </div>
        <p className={styles.info}>
          Oops! The page you are looking for doesn’t exist. Try the button
          below...
        </p>
        <Link href="/">
          <a className={styles.buttonBack}>
            <Button size="default" type="black">
              Back to home
            </Button>
          </a>
        </Link>
      </div>
    </DefaultLayout>
  );
};

export default NotFoundPage;
