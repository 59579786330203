import React from 'react';
import MoneyFooter from '../money-site-footer/money-site-footer';
import { MenuNavigation } from '../money-site-navigation/menu-circular/menu-circular';
import MoneySiteNavigation from '../money-site-navigation/money-site-navigation';

interface Props {
  children?: React.ReactNode;
}

const DefaultLayout = (props: Props) => {
  const menus: MenuNavigation[] = [
    // {
    //   title: 'MoneyFlow',
    //   menuUrl: '/moneyflow',
    // },
    // {
    //   title: 'Superpowers',
    //   menuUrl: '/super-powers',
    // },
    // {
    //   title: 'FutureYou',
    //   menuUrl: '/future-you',
    // },
    // {
    //   title: 'What is Money?',
    //   menuUrl: '/what-is-money'
    // },
    {
      title: 'Money is Closing',
      menuUrl: '/money-is-closing',
    },
    // {
    //   title: 'FAQs',
    //   menuUrl: 'https://help.moneybyafterpay.com/hc/en-au',
    // },
  ];
  const linkGetApp = 'https://moneyau.app.link/lXKuebWrPkb';

  return (
    <>
      <MoneySiteNavigation menu={menus} linkGetApp={linkGetApp} />
      {props.children}
      <MoneyFooter />
    </>
  );
};

export default DefaultLayout;
