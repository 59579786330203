import { useEffect, useState } from 'react';

export const useBodyResize = () => {
  const [marginTop, setMarginTop] = useState(0);

  const measuer = (mutations: MutationRecord[], target: HTMLElement) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === 'style') {
        const marginT =
          Number.parseFloat((target.style.marginTop || '').replace('px', '')) ||
          0;
        setMarginTop(marginT);
        document.documentElement.style.setProperty(
          '--app-banner-height',
          `${target.style.marginTop}`
        );
      }
    });
  };

  useEffect(() => {
    const target = document.body;
    if (!target) return;
    const observer = new MutationObserver((mutations: MutationRecord[]) =>
      measuer(mutations, target)
    );
    observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    return () => observer.disconnect();
  }, []);

  return { marginTop };
};
