import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { getSymbolByName } from '../../helpers/SymbolHelper';
import Icons, { IconType } from '../Icons';
import styles from './money-site-footer.module.scss';
interface FooterTitle {
  title: string;
  url: string;
}
const moneyFlow: FooterTitle[] = [
  // {
  //   title: 'Money Flow',
  //   url: '/moneyflow',
  // },
  // {
  //   title: 'Superpowers',
  //   url: '/super-powers',
  // },
  // {
  //   title: 'FutureYou',
  //   url: '/future-you',
  // },
  // { title: 'Apple Pay', url: '/apple-pay' },
  // { title: 'What is Money?', url: '/what-is-money' },
  // { title: 'FAQs', url: 'https://help.moneybyafterpay.com/hc/en-au' },
  // {
  //   title: 'FAQs',
  //   url: 'https://help.moneybyafterpay.com/hc/en-au',
  // },
  {
    title: 'Complaints',
    url: 'https://www.moneybyafterpay.com/complaints',
  },
  {
    title: 'App Terms & Conditions',
    url: 'https://www.moneybyafterpay.com/terms-and-conditions-app',
  },
  {
    title: 'Account Terms & Conditions',
    url: 'https://www.moneybyafterpay.com/terms-and-conditions-account',
  },
];

// const FAQs: FooterTitle[] = [
//   {
//     title: 'Privacy Notice',
//     url: '/privacy-notice',
//   },
//   {
//     title: 'Open banking',
//     url: '/open-banking',
//   },
//   {
//     title: 'BaaS Product TMDs',
//     url: 'https://www.westpac.com.au/about-westpac/innovation/banking-as-a-service/',
//   },
//   {
//     title: 'Afterpay TMDs',
//     url: '/target-market-determination',
//   },
//   {
//     title: 'Disclosures',
//     url: '/disclosures',
//   },
//   { title: 'Complaints', url: '/complaints' },
// ];
const terms: FooterTitle[] = [
  // {
  //   title: 'App Terms & Conditions ',
  //   url: '/terms-and-conditions-app',
  // },
  // {
  //   title: 'Account Terms & Conditions',
  //   url: '/terms-and-conditions-account',
  // },
  // {
  //   title: 'Apple Pay Terms & Conditions',
  //   url: '/apple-pay-terms-and-conditions',
  // },
  // {
  //   title: 'Online Identity Verification Terms',
  //   url: '/online-identity-verification-terms',
  // },
  {
    title: 'Apple Pay',
    url: 'https://www.moneybyafterpay.com/apple-pay',
  },
  {
    title: 'Apple Pay T&Cs',
    url: 'https://www.moneybyafterpay.com/apple-pay-terms-and-conditions',
  },
  {
    title: 'Afterpay TMDs',
    url: 'https://www.moneybyafterpay.com/target-market-determination',
  },
  {
    title: 'BaaS Product TMDs',
    url: 'https://www.westpac.com.au/about-westpac/innovation/banking-as-a-service/',
  },
];
const MoneyFooter = () => {
  const [footerTerms, setFooterTerms] = useState<string>('');
  useEffect(() => {
    const fetchFooterTerms = async () => {
      const content = await getSymbolByName('[Money is closing] Footer Terms');
      setFooterTerms(content?.data?.blocks[0]?.component?.options?.text);
    };
    fetchFooterTerms();
  }, []);

  const homeHref = '/';
  const renderGirdColum = (listTitle: FooterTitle[]) => {
    return (
      <ul className={styles.gridColumn}>
        {listTitle.map((item: FooterTitle) => {
          if (item.title === 'BaaS Product TMDs')
            return (
              <li key={item.url} className={styles.gridItem}>
                <Link href={item.url}>
                  <a target="_blank" rel="nofollow">
                    {item.title}
                  </a>
                </Link>
              </li>
            );
          return (
            <li key={item.url} className={styles.gridItem}>
              <Link href={item.url}>
                <a>{item.title}</a>
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };
  const renderIconsGrid = () => {
    return null;
    return (
      <>
        <Link href="https://www.facebook.com/moneybyafterpay/">
          <a>
            <Icons type={IconType.Facebook} />
          </a>
        </Link>
        <Link href="https://www.instagram.com/moneybyafterpay/">
          <a>
            <Icons type={IconType.Instagram} />
          </a>
        </Link>
        {/* <Icons type={IconType.Reddit} />
        <Icons type={IconType.ClubHouse} />
        <Icons type={IconType.TikTok} /> */}
      </>
    );
  };
  const renderLogoAfterPay = () => {
    return (
      <div className={styles.afterpayLogo}>
        <Link href={homeHref}>
          <a>
            <Icons type={IconType.MoneyByAfterpay} />
          </a>
        </Link>
      </div>
    );
  };
  // const linkDownLoadApp = 'https://moneyau.app.link/e/r9fZ4bsbQkb';

  return (
    <div className={styles.moneySiteFooter}>
      <div className={styles.footerTop}>
        {renderLogoAfterPay()}
        <div className={styles.gridTitles}>
          {renderGirdColum(moneyFlow)}
          {/* {renderGirdColum(FAQs)} */}
          {renderGirdColum(terms)}
        </div>
        <div className={styles.iconGroup}>
          <div className={styles.iconAppleStore}>
            {/* <Link href={linkDownLoadApp}>
              <a>
                <Icons type={IconType.AppleStore} />
              </a>
            </Link> */}
          </div>
          <div className={styles.iconsGrid}>{renderIconsGrid()}</div>
        </div>
      </div>
      {/* Footer Tablet  */}
      <div className={styles.footerSmall}>
        <div className={styles.footerTopSmall}>
          {renderLogoAfterPay()}
          <div className={styles.iconGroup}>
            <div className={styles.iconAppleStore}>
              {/* <Link href={linkDownLoadApp}>
                <a>
                  <Icons type={IconType.AppleStore} />
                </a>
              </Link> */}
            </div>
            <div className={styles.iconsGrid}>{renderIconsGrid()}</div>
          </div>
        </div>
        <div className={styles.gridTitlesTablet}>
          {renderGirdColum(moneyFlow)}
          {/* {renderGirdColum(FAQs)} */}
          {renderGirdColum(terms)}
        </div>
      </div>
      <div
        className={styles.copyright}
        dangerouslySetInnerHTML={{ __html: footerTerms }}
      />
      <div className={styles.backgroundFullScreen}></div>
      <div className={styles.footerBar}></div>
    </div>
  );
};

export default MoneyFooter;
