import React, { ForwardRefRenderFunction } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

export const tuple = <T extends string[]>(...args: T) => args;
const ButtonTypes = tuple('default', 'mint', 'black', 'olive', 'link');
export type ButtonType = typeof ButtonTypes[number];
export declare type SizeType = 'small' | 'medium' | 'default' | 'large' | 'ex-large' | undefined;
const ButtonShapes = tuple('default', 'circle', 'round');
export type ButtonShape = typeof ButtonShapes[number];
declare const ButtonHTMLTypes: ['submit', 'button', 'reset'];
export declare type ButtonHTMLType = typeof ButtonHTMLTypes[number];
declare const IconPosition: ['left', 'right'];
export declare type IconPositionType = typeof IconPosition[number];
type BaseButtonProps = {
    type?: ButtonType;
    icon?: React.ReactNode;
    iconPosition?: IconPositionType;
    children?: React.ReactNode;
    className?: string;
    size?: SizeType;
    shape?: ButtonShape;
    disabled?: boolean;
    loading?: boolean;
};
export type AnchorButtonProps = {
    href: string;
    target?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps &
    Omit<React.AnchorHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick'>;
export type NativeButtonProps = {
    htmlType?: ButtonHTMLType;
    onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick'>;
export type ButtonProps = Partial<AnchorButtonProps & NativeButtonProps>;

const prefixCls = 'afterpay-money-btn';

const InternalButton: ForwardRefRenderFunction<unknown, ButtonProps> = (props, ref) => {
    const { children, className, htmlType, shape, size, type, icon, iconPosition, ...rest } = props;
    const buttonRef = (ref as never) || React.createRef<HTMLElement>();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
        const { onClick, disabled, loading } = props;
        if (loading || disabled) {
            e.preventDefault();
            return;
        }
        (onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)?.(e);
    };

    let sizeCls = '';
    switch (size) {
        case 'ex-large':
            sizeCls = 'ex-large';
            break;
        case 'large':
            sizeCls = 'large';
            break;
        case 'medium':
            sizeCls = 'med';
            break;
        case 'small':
            sizeCls = 'sm';
            break;
        default:
            break;
    }

    const classes = classNames(
        styles[prefixCls],
        {
            [styles[`${prefixCls}-${type}`]]: type,
            [styles[`${prefixCls}-${shape}`]]: shape !== 'default' && shape,
            [styles[`${prefixCls}-${sizeCls}`]]: sizeCls,
            [styles[`${prefixCls}-icon-only`]]: !children && children !== 0,
        },
        className
    );

    const buttonNode = () => {
        let node = (
            <>
                {iconPosition !== 'right' && icon ? <span className={styles.amicon}>{icon}</span> : null}
                {children ? <span>{children}</span> : null}
                {iconPosition === 'right' && icon ? <span className={styles.amicon}>{icon}</span> : null}
            </>
        );

        if (type === 'link') {
            node = (
                <>
                    {children ? <span>{children}</span> : null}
                    <span className={styles.amicon}>
                        <Button icon={icon} size="small" shape="circle" type="olive" />
                    </span>
                </>
            );
        }

        return (
            <button ref={buttonRef} {...(rest as NativeButtonProps)} type={htmlType} className={classes} onClick={handleClick} data-hover={children}>
                {type === 'link' ? (
                    node
                ) : (
                    <>
                        <div className={styles.none}>{node}</div>
                        <div className={styles.before}>{node}</div>
                        <div className={styles.after}>{node}</div>
                    </>
                )}
            </button>
        );
    };

    return buttonNode();
};

const Button = React.forwardRef<unknown, ButtonProps>(InternalButton);
Button.displayName = 'Button';

export default Button;
