import { createContext } from 'react';
import { Message } from '../types/Message';

interface IContextNotificationProps {
  messages: Message[];
  isShowing: boolean;
  popMessage: () => Message | undefined;
  addMessages: (message: Message) => void;
  setIsShowing: (state: boolean) => void;
}

export const NotificationCtx = createContext({} as IContextNotificationProps);
