import { builder } from '@builder.io/react';
import { BUILDER_API_KEY } from '../shared/env';

builder.init(BUILDER_API_KEY);

export const getAllSymbols = async (
  includeUnpublished = false,
  includeDraft = false
) => {
  const draft = includeDraft ?? 'draft';
  const symbols = await builder.getAll('symbol', {
    options: { noTargeting: true, includeUnpublished },
    query: {
      published: {
        $ni: ['archived', draft],
      },
    },
  });
  return symbols;
};

export const getSymbolByName = async (
  nameSymbol: string,
  includeUnpublished = false
) => {
  const queryOption: any = {
    query: {
      name: {
        $eq: nameSymbol,
      },
    },
    options: {
      includeUnpublished,
      noTargeting: false,
    },
  };

  const symbol = (await builder.get('symbol', queryOption).promise()) || null;
  return symbol;
};
