import { Size, useWindowSize } from './useWindowSize'

export function useMobile(): boolean {
  const windowSize: Size = useWindowSize()
  const windowWidth: number = windowSize.width || 0
  if (windowWidth <= 768) {
    return true
  }
  return false
}
