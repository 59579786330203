interface Options {
  detalY?: number | 0
}

export const scrollToById = (
  id: string,
  options: Options = {},
  delay: number = 10
) => {
  const { detalY = 0 } = options
  setTimeout(() => {
    const element = document.getElementById(id)
    if (!element) {
      return
    }
    const y = element.getBoundingClientRect().top + window.pageYOffset + detalY
    window.scrollTo({ top: y, behavior: 'smooth' })
  }, delay)
}

export const debounce = (func: any, timeout = 300) => {
  let timer: any;
  return (...args: any) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

