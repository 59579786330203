import { Builder } from '@builder.io/sdk';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  getAllMessage,
  getMessage,
  getValidMessage,
} from '../../helpers/MessageHelper';
import { NotificationCtx } from '../../helpers/NotificationContext';
import { useMobile } from '../../hook/useMobile';
import { useScroll } from '../../hook/useScroll';
import { useWindowSize } from '../../hook/useWindowSize';
import { Message } from '../../types/Message';
import { ButtonType } from '../Button';
import Icons, { IconType } from '../Icons';
import Notification from '../money-site-notification/money-site-notification';
import MenuCircular, { MenuNavigation } from './menu-circular/menu-circular';
import styles from './money-site-navigation.module.scss';
import ScrollIndicator from './scroll-indicator/scroll-indicator';
interface Props {
  linkGetApp: string;
  menu: MenuNavigation[];
}

const MoneySiteNavigation = (props: Props) => {
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isShowNoti, setIsShowingNoti] = useState<boolean>(false);
  const { scrollY, scrollDirection } = useScroll();
  const isMobile = useMobile();
  const { linkGetApp, menu } = props;
  const route = useRouter();

  const posMenuY = isMobile ? 100 : 200;
  const { width = 0 } = useWindowSize();

  useEffect(() => {
    (async () => {
      let messages;
      let messageId = route.query['message_id'];
      if (messageId && Builder.isPreviewing) {
        if (Array.isArray(messageId)) {
          messageId = messageId[0];
        }
        messages = await getMessage({ id: messageId }, true);
        if (messages) {
          messages = [messages];
        }
      } else {
        messages = await getAllMessage();
      }
      const validMessage = getValidMessage(messages);
      if (validMessage) {
        setMessages([validMessage]);
      }
    })();
  }, [route.query]);

  useEffect(() => {
    const body = document.body;
    if (!body) {
      return;
    }
    if (width < 576 && isShowMenu) {
      body.classList.add('lock-scroll');
    } else {
      body.classList.remove('lock-scroll');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMenu]);

  useEffect(() => {
    const body = document.body;
    if (!body) {
      return;
    }
    if (isShowNoti) {
      body.classList.add('show-noti');
    } else {
      body.classList.remove('show-noti');
    }
  }, [isShowNoti]);

  const addMessages = (message: Message) => {
    setMessages((mess) => {
      return [...mess, message];
    });
  };

  const popMessage = (): Message | undefined => {
    const message = messages.pop();
    setMessages((mess) => {
      mess.pop();
      return mess;
    });
    return message;
  };

  let type = 'large';
  if (
    (scrollDirection === 'up' || (isMobile && scrollDirection === 'down')) &&
    scrollY > posMenuY
  ) {
    type = 'small';
  }

  const buttonGetAppType: ButtonType = type === 'large' ? 'black' : 'mint';
  // const buttonSize: SizeType = isMobile ? 'medium' : 'large';
  const navBarColor: string = type === 'large' ? 'transparent' : '#000';
  const homeHref = '/';
  const classNameMoneyNavigationContainer = classNames(
    styles.moneyNavigationContainer,
    styles[type],
    isShowMenu && styles.expand
  );
  const classNameMoneyLogo = classNames(
    styles.moneyLogo,
    styles[type],
    isShowMenu && styles.filled
  );
  return (
    <div className={styles.moneySiteNavigation}>
      <NotificationCtx.Provider
        value={{
          messages,
          isShowing: isShowNoti,
          popMessage,
          addMessages,
          setIsShowing: setIsShowingNoti,
        }}
      >
        <Notification />
        <ScrollIndicator color={buttonGetAppType} />
      </NotificationCtx.Provider>
      <div
        className={classNameMoneyNavigationContainer}
        style={{ background: navBarColor }}
      >
        <MenuCircular
          isShowMenu={isShowMenu}
          setIsShowMenu={setIsShowMenu}
          iconStyle={type}
          menuItems={menu}
          linkGetApp={linkGetApp}
        />
        <div className={classNameMoneyLogo}>
          <Link href={homeHref}>
            <a>
              <Icons type={IconType.MoneyLogo} />
            </a>
          </Link>
        </div>
        {/* <div className={styles.btnGetApp}>
          <Link href={linkGetApp || ''}>
            <a>
              <Button size={buttonSize} type={buttonGetAppType}>
                Get the app
              </Button>
            </a>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default MoneySiteNavigation;
