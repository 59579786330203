import classNames from 'classnames';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { debounce } from '../../../helpers';
import useOnClickOutside from '../../../hook/useOutsideClick';
import { useScroll } from '../../../hook/useScroll';
import { useWindowSize } from '../../../hook/useWindowSize';
import Icons, { IconType } from '../../Icons';
import styles from './menu-circular.module.scss';
interface Props {
  iconStyle: string;
  menuItems: MenuNavigation[];
  linkGetApp: string;
  isShowMenu: boolean;
  setIsShowMenu: (isShowMenu: boolean) => void;
}
export interface MenuNavigation {
  title: string;
  menuUrl: string;
}

const MenuCircular = (props: Props) => {
  const { iconStyle, menuItems, setIsShowMenu, isShowMenu } = props;
  const circleBackgroundRef = useRef(null);
  const closeClickRef = useRef(false);
  const { width = 0 } = useWindowSize();
  const { scrollY } = useScroll();
  const [lastScrollY, setLastScrollY] = useState(scrollY);

  useEffect(() => {
    if (width < 577) return;
    if (Math.abs(lastScrollY - scrollY) >= 20) {
      setIsShowMenu(false);
    }
    debounce(setLastScrollY(scrollY), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useOnClickOutside(circleBackgroundRef, () => {
    if (width < 577) return;
    if (isShowMenu) {
      setIsShowMenu(false);
    }
  });

  const showMenuClassName = isShowMenu ? 'show' : 'hide';

  const handleClickMenuItem = () => {
    setIsShowMenu(false);
  };

  const getClassMenuItemActive = (item: MenuNavigation): string => {
    if (typeof window === 'undefined') {
      return '';
    }
    const pathname = window.location.pathname;
    if (item.menuUrl !== pathname) {
      return '';
    }
    return styles.homeTitle;
  };

  const renderMenu = () => {
    return (
      <ul className={styles.menuNavigation + ' ' + styles[showMenuClassName]}>
        {[{ title: 'Home', menuUrl: '/' }, ...menuItems].map(
          (item: MenuNavigation) => (
            <li
              onClick={handleClickMenuItem}
              key={`menu_item_${item.title}`}
              className={styles.menuItem + ' ' + getClassMenuItemActive(item)}
            >
              <Link href={item.menuUrl}>{item.title}</Link>
            </li>
          )
        )}
      </ul>
    );
  };

  const renderDrawer = () => {
    const classNameMenuDrawer = classNames(
      styles.menuDrawer,
      styles[showMenuClassName],
      styles[iconStyle]
    );
    return (
      <div className={classNameMenuDrawer}>
        {renderMenu()}
        {/* <div className={styles.btnGetApp}>
          <a href={linkGetApp} target="_blank" rel="noopener noreferrer">
            Get the app
          </a>
        </div> */}
      </div>
    );
  };
  return (
    <div
      ref={circleBackgroundRef}
      className={
        styles.menuNavigationContainer + ' ' + styles[showMenuClassName]
      }
    >
      <div
        className={
          styles.iconMenuContainer +
          ' ' +
          styles[iconStyle] +
          ' ' +
          styles[showMenuClassName]
        }
      >
        <span
          onClick={() => {
            closeClickRef.current = true;
            setIsShowMenu(!isShowMenu);
          }}
          className={styles.iconMenu + ' ' + styles.iconMenuDesktop}
        >
          <Icons type={IconType.Menu} />
        </span>
        <span
          onClick={() => {
            closeClickRef.current = true;
            setIsShowMenu(!isShowMenu);
          }}
          className={styles.iconMenu + ' ' + styles.iconMenuClose}
        >
          {isShowMenu ? (
            <Icons type={IconType.MenuClose} />
          ) : (
            <Icons type={IconType.Menu} />
          )}
        </span>
        <div className={styles.circleWrapper + ' ' + styles[showMenuClassName]}>
          <div
            className={
              styles.circleBackground + ' ' + styles[showMenuClassName]
            }
          ></div>
        </div>
      </div>
      <div className={styles.menuContainer}>{renderMenu()}</div>
      {renderDrawer()}
    </div>
  );
};

export default MenuCircular;
