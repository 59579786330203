import React, { useRef } from 'react';
import styles from './scroll-indicator.module.scss';
interface Props {
  color?: string;
}

const ScrollIndicator = (props: Props) => {
  const { color } = props;
  const scrollBarRef = useRef<HTMLDivElement>(null);

  let scrollBackgroundColor = '#b2fce4';
  if (color === 'black') {
    scrollBackgroundColor = '#000';
  }

  if (typeof window !== 'undefined') {
    window.onscroll = function () {
      scrollProcess();
    };
  }

  const scrollProcess = () => {
    const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (windowScroll / height) * 100;
    if (scrollBarRef && scrollBarRef.current) {
      scrollBarRef.current.style.width = scrolled + '%';
    }
  };

  return (
    <div
      className={styles.scrollIndicator}
    >
      <div className={styles.scrollBarContainer}>
        <div
          ref={scrollBarRef}
          className={styles.scrollBar}
          style={{ background: scrollBackgroundColor }}
        />
      </div>
    </div>
  );
};

export default ScrollIndicator;
