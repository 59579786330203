import classNames from 'classnames';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NotificationCtx } from '../../helpers/NotificationContext';
import { useBodyResize } from '../../hook/useBodyResize';
import { Message } from '../../types/Message';
import styles from './money-site-notification.module.scss';

const Notification = () => {
  const notificationCtx = useContext(NotificationCtx);
  const [currentMessage, setCurrentMessage] = useState<Message | undefined>();
  const notiRef = useRef<HTMLDivElement | null>(null);
  const { marginTop: bodyMarginTop } = useBodyResize();

  useEffect(() => {
    setCurrentMessage(notificationCtx.popMessage());
  }, [notificationCtx.messages]);

  const measuer = useCallback(() => {
    let height = 0;
    if (!notificationCtx.isShowing) {
      height = notiRef.current?.getBoundingClientRect().height || 0;
    }
    document.documentElement.style.setProperty(
      '--noti-height',
      `${height + bodyMarginTop}px`
    );
  }, [notificationCtx.isShowing]);

  useEffect(() => {
    notificationCtx.setIsShowing(!!currentMessage);
  }, [currentMessage]);

  useEffect(() => {
    if (!notificationCtx.isShowing) {
      document.documentElement.style.setProperty('--noti-height', '0px');
    }
  }, [notificationCtx.isShowing]);

  useEffect(() => {
    if (!notiRef.current) return;
    const target = notiRef.current;
    const observer = new ResizeObserver(measuer);
    observer.observe(target, { box: 'border-box' });
    return () => observer.unobserve(target);
  }, []);

  const _onClose = () => {
    if (currentMessage && currentMessage.id) {
      localStorage.setItem(currentMessage.id, 'true');
      setCurrentMessage(undefined);
    }
  };

  return (
    <div
      className={classNames(styles.moneySiteNotificationContainer, {
        [styles.haveContent]: !!currentMessage,
      })}
    >
      <div
        className={classNames(styles.notificationBox, styles.warning)}
        ref={notiRef}
      >
        <div className={styles.notificationMain}>
          {currentMessage ? (
            <span className={styles.messageContent}>
              {`⚠️ ${currentMessage.content}`}
            </span>
          ) : null}
          <a className={styles.closeButton} onClick={_onClose}>
            <div className={styles.closeIcon}></div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Notification;
